@import "../../../styles/colours.scss";

.Properties {
  .ExpandableContainer {
    padding: 0.75rem;
  }

  p {
    display: inline-block;
    margin: 0 0.25rem 0 0;
  }

  &__list {
    padding: 0;
    margin: 0.25rem 0 0;

    a {
      margin-right: 0.25rem;
    }

    li {
      display: flex;

      .text-link {
        margin-left: auto;
      }
    }
  }
}
