.CancellationPolicy {
  display: flex;
  margin: 0;
  
  .Tooltip {
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }
}
