@import "../styles/colours.scss";

.Spinner {
  height: 1.75rem;
  width: 1.75rem;

  svg path {
    fill: $dark-elephant;
  }
}
