@import "../../../styles/colours.scss";

.CancellationReason {
  &__header {
    margin: 0 1rem;
  }

  &__main {
    margin-top: 1rem;

    &__item {
      margin: 1rem;

      label {
        display: block;
        margin: auto;
      }

      select {
        width: 100%;
        background: transparent;
        border: 1px solid $light-blue;
      }

      textarea {
        margin: 0;
        padding: 0.5rem;
        height: 6rem;
        border: 1px solid $light-blue;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 2rem;

    Button {
      margin-left: 0.5rem;
    }
  }
}
