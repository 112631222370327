.ReservationDetails {

  &__content {
    margin: 0;
  }

  .Button {
    display: block;
    text-align: left;
  }
}
