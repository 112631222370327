@import "../styles/colours.scss";

.PropertyInfo {
  &__title {
    margin: 0;
  }

  &__subtitle {
    margin: 0.5rem 0;
    border-bottom: 1px solid $light-elephant;
    width: fit-content;
  }

  &__container {
    padding-bottom: 1rem;

    &--internal {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 0.5rem;

      &.Maintenance {
        grid-template-columns: 1fr;
      }
    }
  }

  &__address {
    grid-column: 1/span 2;

    a {
      margin-left: auto;
    }

    .Button--link {
      margin-left: 0.5rem;
    }
  }

  &__category {
    display: flex;
    margin: 0.25rem 0;

    .Button--link {
      margin-left: auto;
      align-self: flex-end;
    }
  }

  &__item {
    display: flex;
    flex: 1;
    align-items: center;

    p {
      margin: 0;
    }

    button {
      margin-left: auto;
    }

    &--wifi {
      p {
        margin: 0;
      }
    }

    &--address {
      a {
        margin-left: auto;
      }
    }
  }

  &__content {
    margin-left: 0;
  }
}
