.CancellationDetails {
  &__header {
    margin: 0 1rem;
  }

  &__main {
    margin-top: 1rem;

    &__item {
      margin: 1rem;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 2rem;

    Button {
      margin-left: 0.5rem;
    }
  }
}
