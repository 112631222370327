@import "../styles/colours.scss";
@import "../styles/responsive.scss";

.BookingDetails {
    ul {
        margin-top: 0.5rem;
        padding-inline-start: 1.5rem;
        font-weight: normal;
    }

    &__listing {
        margin: 0.5rem 0 1rem 0;
    }

    .BookingDetails__dates {
        margin: 1.5rem 0 0.5rem 0;
    }

    &__alert {
        margin-bottom: 1.25rem;
    }

    &__guests,
    &__nights {
        font-weight: bold;
        margin-right: 2rem;

        &-number {
            padding: 0 1rem;

            &--success {
                background: $light-green;
            }

            &--warning {
                background: $light-amber;
            }
        }
    }

    &__stay {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 0.5rem;
        
        dl {
            margin: 0;
        }
    }

    &__early-checkin {
        margin: 0;
        display: flex;

        .EarlyCheckinTooltip {
            width: 1rem;
            height: 1rem;
            margin-left: 0.25rem;
        }

    }

    &__info {
        margin: 0;
    }
}