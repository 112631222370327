.UpdateReservationTimes {
  &__section {
    margin: 0.5rem 0;
    display: flex;
    flex-direction: column;

    &__item {
      margin-bottom: 0.75rem;

      select {
        margin-left: 0.75rem;
      }
    }
  }

  .Button {
    max-width: 5rem;
  }


}
