@import "../styles/colours.scss";
@import "../styles/responsive.scss";

$img-size: 2.625rem;

.User {
  &__name {
    font-size: 1rem;
    font-style: oblique;
  }

  &__img {
    background-color: $light-elephant;
    border: 2px solid $blue;
    border-radius: 50%;
    height: $img-size;
    margin-left: 0.75rem;
    min-height: $img-size;
    min-width: $img-size;
    width: $img-size;

    &:hover {
      cursor: pointer;
    }
  }

  &__profile {
    position: relative;
  }

  &__close-menu {
    bottom: 0;
    left: 0;
    height: 100%;
    opacity: 0;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
  }

  &__menu {
    background-color: rgba($white, 0.9);
    border: 1px solid $light-elephant;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0;
    padding: 0.75rem 1.25rem 1.25rem 1.25rem;
    position: absolute;
    right: 0;
    width: 15rem;
    z-index: 99;
  }

  &__logout-button {
    background-color: $white;
    border: none;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba($dark-elephant, 0.25);
    font-weight: bold;
    padding: 0.5rem;
    margin-top: 0.75rem;
    transition: all 0.3s ease-in;

    &:hover {
      box-shadow: 0 1px 2px 0 rgba($dark-elephant, 0.25);
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }
}
