.Actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 1rem 0;

    .Button {
        margin-right: 2rem;
    }

    .Modal {
        min-height: auto;
    }

    .ModalButtons {
        display: flex;

    }
}
