@import "../../../styles/colours.scss";

.GuestDetails {
  margin: 1.5rem 0;

  h2 {
    font-weight: normal;
  }

  &__basic {
    align-items: center;
    display: flex;

    h4 {
      margin: 0;
    }
  }

  &__guest {
    display: flex;
    align-items: center;
  }

  &__icon {
    width: 1.25rem;

    &--verified {
      color: $green;
    }
    &--unverified {
      color: $red;
    }
  }

  &__verified {
    padding-left: 0.5rem;
    > div {
      display: flex;
      align-items: center;
    }
  }

  &__reviews {
    &--negative {
      color: $red;
      margin-left: 0.1rem;
    }
    &--neutral {
      color: $amber;
      margin-left: 0.1rem;
    }
    &--positive {
      color: $green;
    }
  }

  &__sentiment {
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
  }

  &__sentiment-star {
    display: flex;
    align-items: center;
    color: $blue;
    margin-right: 0.25rem;

    svg {
      width: 1.25rem;
    }
  }
}
