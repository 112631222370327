@import "../styles/colours.scss";
@import "../styles/typography.scss";

.BookingOrPropertyNotes {
  &__note {
    border-bottom: 1px solid $light-elephant;
    padding-bottom: 0.75rem;

    &:last-child {
      border-bottom: none;
    }

    p {
      margin: 0.75rem 0;
    }
  }

  &__link-wrapper {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $light-elephant;
    margin: 0.75rem 0;
  }

  &__link {
    display: block;
    color: $green;

    &--delete {
      color: $red;
    }
  }
}
