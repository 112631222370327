@import "../../styles/colours.scss";
@import "../../styles/responsive.scss";
@import "../../styles/typography.scss";

.Messenger {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.75rem 0.5rem 0.5rem 0.5rem;
  background-color: $white;
  box-shadow: 0 0 2rem rgba($dark-elephant, 0.1);

  &--static {
    box-shadow: none;
    padding: 0;
    position: relative;
  }

  &__container {
    margin: 0 auto;
    max-width: $tablet;
    position: relative;
  }

  &__close-button {
    align-content: center;
    background-color: $white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    left: 50%;
    justify-content: center;
    margin-left: -0.4rem;
    outline: none;
    padding: 0.25rem;
    position: absolute;
    top: -1.25rem;

    svg {
      height: 0.8rem;
      transition: all 0.2s ease-in;
      width: 0.8rem;
    }

    &--expand {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &__textarea {
    border: 2px solid darken($light-elephant, 10%);
    border-radius: 0.25rem;
    height: 4rem;
    line-height: 1.5;
    margin-bottom: 0;
    padding: 1.25rem;
    transition: all 0.2s ease-out;
    width: 100%;

    // Maintain height if user is selecting canned response
    &--active {
      height: 15rem;
    }

    &:focus {
      border-color: $blue;
      outline: none;
    }
  }

  &__actions {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    height: 0;
    opacity: 0;

    &--active {
      height: auto;
      opacity: 1;
      margin-top: 0.5rem;
    }

    @include wider($mobile-landscape) {
      align-items: center;
    }
  }

  &__inner {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    @include wider($mobile-landscape) {
      flex-direction: row;
      align-items: center;
    }
  }

  .Button {
    min-width: 9rem;
  }

  &__canned-messages {
    width: 50%;

    @include wider($mobile-landscape) {
      width: 40%;
    }
  }

  &__time {
    color: darken($light-elephant, 25%);
    margin: 0.5rem 0 0 0;
    text-align: right;
    order: 1;

    @include wider($mobile-landscape) {
      order: unset;
      margin: 0 0.5rem;
    }
  }

  &__textarea-container {
    position: relative;
  }

  &__clear {
    position: absolute;
    top: 2px;
    right: 2px;
    color: $red;
    display: flex;
    align-items: center;
    background-color: rgba($white, 0.9);
    cursor: pointer;
    padding: 0.5rem;
    border: none;
    appearance: none;

    &:hover,
    &:focus {
      border: none;
      outline: none;
    }

    svg {
      fill: $red;
      width: 1rem;
      height: 1rem;
      margin-right: 0.25rem;
    }
  }
}
