@import "../styles/colours.scss";

.Alert {
  background: $light-blue;
  border-left: 0.25rem solid;
  border-color: $blue;
  color: $blue;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  white-space: break-spaces;

  &__title {
    font-weight: bold;
    margin: 0.25rem 0.25rem 0.25rem 0;
  }

  &__message {
    margin: 0.25rem 0;
    display: block;

    &--token {
      margin: 0.5rem 0;
      padding: 0.25rem 0;

      a {
        font-weight: bold;
      }
    }
  }

  &__extra {
    margin-left: auto;
  }

  &--inline {
    p {
      display: inline-block;
    }
  }

  &--block {
    p {
      display: block;
    }
  }

  &--success {
    background: $light-green;
    border-color: $green;
    color: $green;
  }

  &--fail {
    background: $light-red;
    border-color: $red;
    color: $red;
  }

  &--pending {
    background: $light-amber;
    border-color: $amber;
    color: $amber;
  }

  &--info {
    background: $light-blue;
    border-color: $blue;
    color: $blue;
  }
}
