@import "../styles/colours.scss";

.HostPreferences {
  &__title {
    display: inline-block;
  }

  &__container {
    padding-top: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
  }

  &__section {
    margin-top: 0;

    dd {
      margin: 0;
    }
  }

  .text-error {
    margin: 0 0 1.25rem;
  }

  .show-dashboard {
    margin-bottom: 1rem;
  }
}
