@import "../styles/colours.scss";

$img-size-large: 5rem;
$img-size-small: 3rem;

.GuestImage {
  background-color: $light-elephant;
  border: 2px solid $amber;
  border-radius: 100%;
  min-height: $img-size-large;
  height: $img-size-large;
  margin-right: 1.25rem;
  min-width: $img-size-large;
  width: $img-size-large;

  &--small {
    min-height: $img-size-small;
    height: $img-size-small;
    min-width: $img-size-small;
    width: $img-size-small;
  }

  &.accepted {
    border-color: $green;
  }

  &.denied {
    border-color: $red;
  }
}
