@import "../styles/colours.scss";

.Loading {
  align-items: center;
  display: flex;
  margin: 1.25rem 0;

  &__message {
    color: $blue;
    margin-left: 0.5rem;
  }
}
