@import "./colours.scss";
@import "./responsive.scss";

@font-face {
  font-family: Moderat;
  src: local(Moderat),
    url(../assets/fonts/Moderat-Regular.woff2) format("woff2"),
    url(../assets/fonts/Moderat-Regular.woff) format("woff");
}

@font-face {
  font-family: Stuart;
  src: local(Stuart),
    url(../assets/fonts/Stuart-Titling-Regular-205TF.woff2) format("woff2"),
    url(../assets/fonts/Stuart-Titling-Regular-205TF.woff) format("woff");
}

$default-font: Moderat,
Arial,
Helvetica,
sans-serif;

$font-sizes: (
  h1: 2.441rem,
  h2: 1.953rem,
  h3: 1.563rem,
  h4: 1.25rem,
  h5: 1rem,
  small: 0.85rem
);

a,
.text-link {
  color: $blue;
  text-decoration: none;
  transition: all 0.3s ease-in;

  &:hover {
    cursor: pointer;
    color: darken($blue, 10%);
  }
}

.text-warning {
  color: $amber;
}

.text-success {
  color: $green;
}

.text-error {
  color: $red;
}

.text-info {
  color: $blue;
}

.header {
  font-size: 1.125rem;
  font-family: Stuart, Arial, Helvetica, sans-serif;
}

.big-header {
  font-size: 1.25rem;
  font-family: Stuart, Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.label-info {
  font-size: 0.875rem;
  text-transform: uppercase;
  color: $dark-elephant;
  margin-bottom: 0.5rem;
}