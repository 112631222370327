@import "../../../styles/colours.scss";

.Alteration {
  background: $light-blue;
  color: $blue;
  padding: 1rem;

  .Button:first-of-type {
    margin-right: 1.25rem;
  }

  &__title {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  &__buttons {
    margin-top: 1rem;
    display: flex;
  }

  &__info {
    margin-top: 1rem;
    font-weight: bold;
    display: flex;
    align-items: center;

    .Button {
      margin-left: 1rem;
      cursor: pointer;
    }
  }

}
