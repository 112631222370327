@import "../../../styles/colours.scss";

.AlterationRequest {
  margin: 0.5rem 0;

  &__section {
    padding: 0.75rem 0;
    display: flex;
    flex-wrap: wrap;

    &__date {
      margin-right: 0.5rem;
      max-width: 10rem;
      margin-bottom: 0.5rem;
    }

    &__datepicker, &__price {
      max-width: 9rem;
    }

    &__guests {
      margin-right: 0.5rem;
      max-width: 10rem;
      margin-bottom: 0.5rem;
    }

    &__guest_number {
      max-width: 9rem;
    }

    &__buttons {
      display: flex;
      flex-wrap: wrap;
      margin-right: 1rem;
    }

    &__prices, &__button {
      margin-right: 1rem;
    }

    label {
      margin-bottom: 0.25rem;
      display: inline-block;
    }
  }
}
