@import "../styles/colours.scss";
@import "../styles/mixins.scss";

.Button {
  align-items: center;
  border: 2px solid transparent;
  border-radius: 0.25rem;
  padding: 0.75rem 1.25rem;
  transition: all 0.3s ease-in;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  svg {
    height: 1rem;
  }

  &--link {
    border: none;
    color: $blue;
    line-height: inherit;
    background-color: transparent;
    padding: 0;

    &:hover {
      color: darken($blue, 10%);
      cursor: pointer;
    }
  }

  &--positive {
    background: $black;
    color: $white;

    &:hover,
    &:focus {
    @include hover-box-shadow;
    }
  }

  &--negative {
    background: $white;
    border: 2px solid $dark-elephant;
    color: $dark-elephant;

    &:hover,
    &:focus {
      border-color: darken($dark-elephant, 10%);
      color: darken($dark-elephant, 10%);
    }
  }

    &--success {
      background: $green;
      border: 2px solid $green;
      color: $white;
  
      &:hover,
      &:focus {
        border-color: darken($green, 10%);
        background: darken($green, 10%);
      }
    }
 
  &--danger {
    background: $red;
    border: 2px solid $red;
    color: $white;

    &:hover,
    &:focus {
      border-color: darken($red, 10%);
      background: darken($red, 10%);
    }
  }

    &--info {
      background: $blue;
      border: 2px solid $blue;
      color: $white;
  
      &:hover,
      &:focus {
        border-color: darken($blue, 10%);
        background: darken($blue, 10%);
      }
    }

  &[disabled] {
    background: darken($light-elephant, 5%);
    color: darken($light-elephant, 25%);
    border-color: darken($light-elephant, 5%);

    &:hover {
      cursor: default;
      pointer-events: none;
    }
  }

  &.iconOnly {
    padding: 0.75rem;
    
  }

  &:not(&.iconOnly) {
    svg {
      margin-right: 0.5rem;
    }
  }

  &.small {
    padding: 0.5rem 0.75rem;
  }
}

.Fab-button {
  display: flex;
  justify-content: flex-end;

  a {
    box-shadow: 0 0.5rem 1.25rem rgba($dark-elephant, 0.3);
    margin-left: auto;
    position: fixed;
    bottom: 3.75rem;

    span[role="img"] {
      margin-right: 0.25rem;
    }
  }
}
