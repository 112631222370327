@import "../../styles/colours.scss";

.Maintenance {
  &__title {
      padding-bottom: 1rem;
      margin: 1rem 1.75rem 0 1.75rem;
      border-bottom: 1px solid $light-elephant;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 1.5rem;
        font-family: Stuart, Arial, Helvetica, sans-serif;
      }

      a {
        cursor: pointer;
      }

    }
}

.MaintenanceInfoContainer {
  border-bottom: 1px solid $light-elephant;
  margin-top: 1.5rem;
  padding-bottom: 0.5rem;
}

.MaintenanceItem {
  margin: 1rem 1.75rem;
  position: relative;

  dt {
    text-transform: uppercase;
    color: $dark-elephant;
  }

  dd {
    margin-left: 1rem;
  }

  .edit-button {
    position: absolute;
    right: 0;
  }
}


.MaintenanceForm {
  display: flex;
  flex-direction: column;
  padding: 2rem;

  &.Edit {
    padding-top: 0;
  }

  .Button {
    margin-top: 2rem;
  }

  &__inner {
    display: flex;
    flex-direction: column;
  }
}
