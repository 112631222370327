@import "../../../styles/colours.scss";
@import "../../../styles/responsive.scss";

.ThreadPreview {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-bottom: 1px solid $light-elephant;
  justify-content: space-between;
  padding: 0.75rem;
  flex-wrap: wrap;

  &__details {
    align-items: center;
    display: flex;
    width: 45%;
  }

  &__view {
    display: flex;
    justify-content: space-between;

  }

  &__airbnb {
    display: flex;
    flex-direction: column;
  
  }

  &__right {
    width: 50%;
  }

  &__dates {
    display: flex;
    flex-direction: column;
  }
}
