.UnhappyGuestForm {
  &__info {
    height: 10rem;
    padding: 0.5rem;
  }

  &__actions {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: auto;

    button {
      padding: 0.5rem 1rem;
      margin-right: 1.25rem;
    }
  }
}
