.Images {
    display: flex;
    flex-direction: row;
    margin: 0.5rem 0 1rem 0;
   
}


.Image{
     width: 7rem;
     margin-right: 0.5rem;
     cursor: pointer;
     position: relative;
     height: 9rem;

     &:last-child {
         margin-right: 0;
     }

     img {
         position: absolute;
         left: 50%;
         top: 50%;
         width: 100%;
         height: 100%;
         transform: translate(-50%, -50%);
     }
 }