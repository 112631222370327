@import "../../../styles/colours.scss";

.Property {
  &__item {
    margin: auto auto 0.5rem;
    display: flex;
    flex-flow: row wrap;

    :first-child {
      width: 8rem;
    }
  }

  &__link {
    margin-right: 2rem;

    &--set {
      color: $green;
    }

    &--reset {
      color: $amber;
    }
  }
}
