@import "../../styles/responsive.scss";
@import "../../styles/colours.scss";
@import "../../styles/typography.scss";

.App {
  max-width: $tablet;
  margin: 0 auto;
  padding-bottom: 0.75rem;
  background-color: $white;

  @include wider($tablet) {
    box-shadow: 0 0.1rem 0.3rem rgba($elephant, 0.4);
  }
}

.App-loading {
  text-align: center;
}


.app-background {
  @include wider($tablet) {
    background: url("../../assets/shapes-background.svg") no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
  }
}
