@import "../styles/colours.scss";

.Modal-overlay {
  border: none;
  outline: none;
  background-color: $white;
  opacity: 0.8;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.Modal {
  background-color: $white;
  border-radius: 0.5rem;
  box-shadow: 0 10px 20px 10px rgba($dark-elephant, 0.3);
  display: flex;
  flex-direction: column;
  left: 50%;
  min-height: 30vh;
  max-height: 90%;
  min-width: 25rem;
  padding-bottom: 0.5rem;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  &__title {
    border-bottom: 2px solid $light-elephant;
    padding: 1.25rem;

    h4 {
      margin: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 1.25rem;
    overflow: scroll;
  }
}
