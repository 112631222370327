.AlterationDetails {
  &__title {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  &__message {
    margin-bottom: 0.25rem;
  }
}
