@import "~normalize.css";
@import "./typography.scss";
@import "./forms.scss";

* {
  box-sizing: border-box;
}

html {
  font-size: 14px;
  font-family: $default-font;
  line-height: 1.5;

  @include wider($tablet) {
    font-size: 16px;
  }
}

body {
  margin: 0;
  color: $black;
}

p,
h1,
h2,
h3,
h4 {
  margin: 0;
}

::placeholder {
  color: $elephant;
}

ul,
li {
  padding: 0;
  list-style-type: none;
}

a {
  cursor: pointer;
  color: $black;
}

svg,
svg:not(:root) {
  overflow: visible;
}

body {
  background-color: $white;
}

.tippy-tooltip [x-circle] {
  border-radius: 4px;
}


.tooltip {
  background-color: $light-blue;
  padding: 1rem;
}


.AppHeader {
  box-shadow: 0 -10px 23px 8px $light-elephant;
  padding: 0 0.5rem 0.75rem;
  margin-bottom: 1rem;

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;


    h4 {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 30rem;
    }
  }
}