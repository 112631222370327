@import "../../../styles/colours.scss";

.BookingActions {
  align-items: center;
  display: flex;

  .Button:first-of-type {
    margin-right: 1.25rem;
  }

  &__expiry {
    margin-left: 1.25rem;
  }
}
