.GuestVerificationForm {
  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  .Checkbox label {
    line-break: anywhere;
  }
}
