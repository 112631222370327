@import "../../../styles/colours.scss";

.BookingComments {
  border-bottom: 1px solid $light-elephant;
  margin: 1.25rem 0;

  &__title {
    display: inline-block;
    padding-bottom: 0.75rem;

    &--warning {
      padding: 0 0.25rem;
      color: $red;
    }
  }

  &__comment {
    background: $light-blue;
    border-radius: 5px;
    margin-top: 1rem;
    padding: 1.25rem;

    &:first-child {
      margin-top: 0;
    }
  }
}
