.Thread {
    padding: 0 0.75rem;
}

.instructions-verification-tasks {
    margin-bottom: 1.5rem;
}

.instructions-verification-tasks:empty {
    margin-bottom: 0;
}