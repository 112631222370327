@import "../../../styles/colours.scss";
@import "../../../styles/mixins.scss";

.Conversation {
  display: flex;
  flex-direction: column;
  margin: 2.5rem 0 10rem;
}

.Conversation__message {
  @include conversation-styles;

  &--sent {
    background-color: lighten($light-elephant, 3%);
  }

  &--third-party {
    background-color: lighten($amber, 40%);
  }
}

.Conversation__message ~ .Conversation__status {
  margin-top: 0;
}

.Conversation__status {
  border-bottom: 1px solid $light-elephant;
  border-top: 1px solid $light-elephant;
  margin: 1.25rem 0;
  padding: 0.75rem;
  text-align: center;

  p {
    margin: 0;
  }
}

.Conversation__image {
  @include conversation-styles;

  padding: 0.5rem;
  text-align: center;
  margin-top: 0.5rem;

  a {
    display: block;
    margin: 0;
  }

  img {
    border: 1px solid $light-elephant;
    border-radius: 0.25rem;
    max-height: 20rem;
  }
}

.Conversation__action {
  @include conversation-styles;

  background: $light-blue;
  padding: 0.5rem;
  text-align: center;
}

.Conversation__booking-details {
  margin-top: 0.75rem;
}

.Conversation__loading {
  justify-content: center;
}
