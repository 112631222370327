@import "./colours.scss";

select {
  height: 2.25rem;

  &:focus {
    outline: none;
  }
}

label {
  font-weight: bold;
  margin-right: 0.75rem;
}

textarea {
  border: 2px solid darken($light-elephant, 10%);
  border-radius: 0.25rem;
  height: 12.5rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  transition: all 0.3s ease-in;
  width: 100%;

  &:focus {
    border-color: $blue;
    outline: none;
  }
}

.Checkbox {
  display: flex;
  align-items: baseline;
  margin-bottom: 0.4rem;

  input {
    margin-right: 0.4rem;
  }
}
