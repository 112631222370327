@import "../../../styles/colours.scss";

.Version {
  text-align: center;
}

.Version__message {
  color: darken($light-elephant, 10%);
  margin: 0;
}
