@import "../styles/colours.scss";
@import "../styles/mixins.scss";

.Input {
  padding: 0.5rem 0.75rem;
  border: 1px solid $dark-elephant;
  border-radius: 5px;
  margin: 0.5rem 0 0;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
  font-family: Moderat, Arial, Helvetica, sans-serif;

  &:not(textarea) {
    line-height: 1.375rem;
    height: 2.5rem;
  }


  &:hover {
    @include hover-box-shadow;
  }

  &:focus {
    box-shadow: 0 0 0.375rem $dark-elephant;
  }

  &:focus-visible {
    outline: 0.5px solid $dark-elephant;
  }

  &--invalid {
    &:not(:focus) {
      border: 1px solid $red;
    }
  }

  &__label {
    font-size: 0.875rem;
    margin: 1.25rem 0.25rem 0 0.25rem;
    text-align: left;

    &:first-child {
      margin-top: 0;
    }

    &.withIcon {
      display: flex;
      align-items: center;

      svg {
        margin-left: 0.25rem;
      }
    }
  }
}

.Radio {
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
  font-size: 0.875rem;

  &:first-of-type {
    margin-bottom: 0.75rem;
  }

  input {
    order: -1;
    margin-right: 0.25rem;
    width: 1.25rem;
    height: 1.25rem;
  }
}
