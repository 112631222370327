@import "../../styles/colours.scss";

.Login {
  text-align: center;

  &__error {
    margin-bottom: 2.5rem;
  }

  &__button {
    display: flex;
    justify-content: center;
  }
}
