@import "../styles/colours.scss";
@import "../styles/mixins.scss";
@import "../styles/responsive.scss";

.SearchBar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0;
  align-items: center;

  @include wider($mobile-landscape) {
    justify-content: flex-end;
  }

  &__search {
    display: flex;
  }

  input {
    width: 15rem;
    padding: 0.75rem 0.75rem;
    border: 1px solid $dark-elephant;
    border-radius: 5px;
    font-size: 1rem;
    transition: all 0.3s ease-in-out;
    font-family: Moderat, Arial, Helvetica, sans-serif;
    line-height: 1.375rem;
    max-height: 2.625rem;
    margin-right: 1rem;

    &:hover {
      @include hover-box-shadow;
    }


    &:focus {
      border-color: $blue;
      outline: none;
    }

    &::placeholder {
      color: darken($light-elephant, 15%);
      font-style: italic;
    }
  }
}
