.Footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 1rem 0;
    margin-bottom: 5rem;

    >.Button {
        display: none;
    }
}