$white: #ffffff;
$black: #000000;
$light-elephant: #e6e6e6;
$elephant: #b3b3b3;
$dark-elephant: #373737;

// Info
$blue: #3498db;

// Success
$green: #27ae60;

// Pending
$amber: #e67e22;

// Fail
$red: #e74c3c;


$light-blue: lighten($blue, 35%);
$light-red: lighten($red, 33%);
$light-amber: lighten($amber, 33%);
$light-green: lighten($green, 40%);