@import "../../styles/colours.scss";

.Search {
  &__results {
    .Search__result:only-child {
      border-bottom: none;
    }
  }

  &__result {
    padding: 0 0.75rem;
  }

  &__guest-info {
    align-items: center;
    display: flex;
    margin: 1rem 0;
  }
}
