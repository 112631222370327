@import "../styles/colours.scss";

.Notification {
  background-color: $white;
  border: 1px solid $green;
  border-left: 0.5rem solid $green;
  border-radius: 0.25rem;
  box-shadow: 0 5px 0.75rem 0 rgba(0, 0, 0, 0.3);
  color: $green;
  min-width: 18rem;
  max-width: 24rem;
  padding: 0.75rem 0.75rem;
  position: fixed;
  right: 1.25rem;
  transition: all 0.3s ease-in;
  top: 1.25rem;
  z-index: 999;

  p {
    margin: 0;

    &:first-of-type {
      margin-right: 1.75rem;
    }
  }

  &__close {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0.75rem;
    position: absolute;
    right: 0;
    top: 0;

    &:hover {
      cursor: pointer;
    }

    svg {
      stroke: $green;
      width: 1rem;
    }
  }

  &--negative {
    border-color: $red;
    color: $red;

    svg {
      stroke: $red;
      width: 1rem;
    }
  }
}

.animate-ease-enter {
  opacity: 0.01;
  transform: translateY(-100%);
}

.animate-ease-enter.animate-ease-enter-active {
  opacity: 1;
  transition: all 300ms ease-in;
  transform: translateY(0%);
}

.animate-ease-leave {
  opacity: 1;
  transform: translateY(0%);
}

.animate-ease-leave.animate-ease-leave-active {
  opacity: 0.01;
  transform: translateY(-100%);
  transition: all 300ms ease-in;
}
