@import "../styles/colours.scss";


.ExpandableContainer {
  border-bottom: 1px solid $light-elephant;
  margin-bottom: 1.25rem;
  padding-bottom:0.75rem;
}

.ExpandableArea {
  &__link {
    align-items: center;
    background-color: transparent;
    border: none;
    color: $blue;
    display: flex;
    justify-content: space-between;
    padding: 0 0 0.5rem;
    transition: all 0.3s ease-in;
    width: 100%;

    :first-letter {
      text-transform: capitalize;
    }

    svg {
      margin-left: auto;
    }

    &:hover,
    &:focus {
      outline: none;
    }

    &:hover {
      color: darken($blue, 10%);
      cursor: pointer;
    }
  }

  &__in-line {
    border: none;
    padding-bottom: 0.2rem;
    transition: all 0.3s ease-in;
    font-weight: bold;

    &:hover,
    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
      filter: brightness(90%);
    }
  }
}
