@mixin conversation-styles {
  border: 1px solid darken($light-elephant, 10%);
  border-radius: 0.25rem;
  margin-bottom: 1.25rem;
  padding: 1.25rem;
}


@mixin hover-box-shadow() {
  box-shadow: 0 0.1rem 0.3rem rgba($dark-elephant, 0.8);
}