.GuestFlagForm {
  &__group {
    margin-bottom: 1.25rem;
  }

  textarea {
    height: 10rem;
    margin: 0.25rem 0 0;
    padding: 0.75rem;
  }

  &__actions {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: auto;

    button:first-of-type {
      margin-right: 1.25rem;
    }
  }
}
