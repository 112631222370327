@import "../styles/colours.scss";

.PropertySection {
  margin-top: 0;
  
  &__content {
    margin: 0;
  }

  &__copy {
    margin-left: 0.5rem;
  }
}
